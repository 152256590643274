import React from 'react';
import { Link } from 'gatsby';

import config from '../../../config/config';
import Logo from '../../data/logos/FishermanRedTextLogo.svg';
import '../../css/pages/privacy-policy.css';

export default class PrivacyPolicy extends React.Component {
  render() {
    return (
      <div>
        <div className="privacy-logo-container">
          <Link to="/" href="/">
            <img id="privacy-logo" src={Logo} alt="Fisherman Logo" />
          </Link>
        </div>
        <div id="privacy-title">Privacy Policy</div>
        <div id="privacy-content">
          Last Updated: 04/09/2021
          <br />
          <br />
          This Privacy Policy (“policy”) discloses what privacy practices{' '}
          <a href={config.baseConfig.siteUrl}>Fisherman</a> Technologies, Inc. (“Fisherman”, “we”,
          “us” or “our”) uses to collect and use information pertaining to each of our unregistered
          visitors (“Visitor” or “you”) and registered users (“User” or “you”), related to their
          access to and use of Fisherman’s websites, including{' '}
          <a href={config.baseConfig.siteUrl}>www.gofisherman.com</a> and its subdomains, as well as
          user websites and additional applications and services provided by us. It will notify you
          of the following:
          <ol>
            <li>
              What personally identifiable information is collected from you through the website,
              how it is used and with whom it may be shared.
            </li>
            <li>What choices are available to you regarding the use of your data.</li>
            <li>The security procedures in place to protect the misuse of your information.</li>
            <li>Actions you might take if you have concerns or questions about the policy.</li>
          </ol>
          If you read and fully understand this policy, and remain opposed to our practices, you
          must immediately leave this website, application or service, and avoid or discontinue all
          use of any of our Services. If you have any questions or concerns regarding this policy,
          please contact us at info@gofisherman.com.
          <br />
          <br />
          We reserve the right to modify this policy at any time and will update the “Last Updated”
          note at the top of the policy document, so please review it carefully.
          <br />
          <br />
          <b>1. &nbsp;Information Collection, Use, and Sharing</b>
          <br />
          <br />
          By using Fisherman services you represent that you are at least the age of majority in
          your state or province of residence, or that you are the age of majority in your state or
          province of residence and you have given us your consent to allow any of your minor
          dependents to use this site.
          <br />
          <br />
          If we are acquired or merged with another company, your information may be transferred to
          the new owners.
          <br />
          <br />
          We collect information about our visitors and users which are provided to us or
          automatically collected by use of our website and services, including personal information
          like email addresses, name, and inquiries submitted through contact forms websites we
          create. Any information that is not personal does not allow us to specifically identify a
          user or visitor, only to understand general and aggregate user behaviour. This information
          includes date and time stamps, mouse, scroll, heatmap, and click behavior, as well as
          operating system, browser, screen, ISP, keyboard. We may use certain technologies like
          tags, cookies, beacons, and scripts to keep track of user preferences and overall improve
          user experience. These might also be used by our registered partners for targeted
          behavioral understanding and advertising, depending on their specific service.
          <br />
          <br />
          We also collect personal information that individuals directly provide to us like name,
          email, password, contact, billing, language, and location information, as well as
          connected third party services and any other information or access directly provided to us
          through our form or explicitly requested.
          <br />
          <br />
          We may request Facebook credentials in order to post photos from Instagram accounts on
          corresponding websites.
          <br />
          <br />
          We might collect similar information regarding the users of Fisherman websites in order to
          improve the experience of our users. You are responsible for the consent, security, and
          usage of the personal information of your users. We encourage our users to develop their
          own privacy policies for their websites.
          <br />
          <br />
          Unless you ask us not to, we may contact you via email in the future to tell you about
          specials, new products or services, or changes to this privacy policy.
          <br />
          <br />
          We store and maintain all listed information above at most in perpetuity in the United
          States of America in our Amazon Web Services servers located in Northern Virginia.
          <br />
          <br />
          We use the collected information in order to enhance the experience of our users,
          including improving our website technology and design based on usage metrics, to determine
          what services might be more or less useful, to better understand and service the needs of
          our users (including information we maintain about previous users), and relay additional
          benefits to both visitors and users.
          <br />
          <br />
          General non-specific statistics about how Fisherman websites perform may be used in
          marketing and advertising. We may share personal and non-personal information with our
          registered partners whose services we use and collaborate with in order to enhance our
          users’ experience with Fisherman. These service partners include hosting, server, and
          content delivery services, security, data, analytics, domain name, legal, email, customer
          management, marketing, content, financial, and payment processing and billing services,
          social media, as well as integration partners whose technologies exist on our websites.
          Each of their policies regarding privacy depend on the nature of their business and
          relationship to our own.
          <br />
          <br />
          We may also share your information when legally required, without needing to provide
          notice to users or visitors.
          <br />
          <br />
          <b>2. &nbsp;Personal Information Rights</b>
          <br />
          <br />
          If you request to access, edit, or restrict the use of your personal information and we
          have verified your identity, we will adhere to this request and reserve the right to
          charge fees for such requests as permitted by the law. You will be able to edit or update
          certain personal information collected directly from you via your login credentials and
          our dashboard. If a request exists that cannot be managed directly by the user, email{' '}
          <a href="mailto:info@gofisherman.com">info@gofisherman.com</a>. If you delete your
          account, there may be certain information that is not possible to re-access since we may
          not maintain the information in our databases.
          <br />
          <br />
          You have the right to file a complaint with your authority for data protection, but we
          encourage you to speak with our team beforehand.
          <br />
          <br />
          Note that when you click links that draw away from our services, we are no longer
          responsible for the privacy practices and suggest you read their privacy notices.
          <br />
          <br />
          <b>3. &nbsp;Security</b>
          <br />
          <br />
          We take precautions to protect your information. Among other things, we use HTTPS and
          industry standard SSL/TLS encryption for all Fisherman websites. When you submit sensitive
          information via the website, your information is protected both online and offline.
          <br />
          <br />
          Wherever we collect sensitive information (such as credit card data), that information is
          encrypted and transmitted to us in a secure way. You can verify this by looking for a lock
          icon in the address bar and looking for "https" at the beginning of the address of the Web
          page.
          <br />
          <br />
          While we use encryption to protect sensitive information transmitted online, we also
          protect your information offline. Only employees who need the information to perform a
          specific job (for example, billing or customer service) are granted access to personally
          identifiable information. The computers/servers in which we store personally identifiable
          information are kept in a secure environment.
          <br />
          <br />
          Our payment processing is provided by Stripe Inc. Information is stored through Stripe’s
          data storage and application, on a secure server behind a firewall.
          <br />
          <br />
          While we will work diligently and earnestly to ensure security and protection of personal
          information, we cannot guarantee the absolute protection. We advise not posting
          information on public-facing areas of Fisherman websites that you do not wish to make
          public.
          <br />
          <br />
          If you feel that we are not abiding by this privacy policy, you should contact us
          immediately via email at <a href="mailto:info@gofisherman.com">info@gofisherman.com</a>.
          You may also contact us by mail at:
          <br />
          <br />
          FISHERMAN TECHNOLOGIES INC.
          <br />
          198 Tremont St. Unit 420
          <br />
          Boston MA 02116
        </div>
      </div>
    );
  }
}
